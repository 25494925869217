import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { graphql } from "gatsby"
import EventDetail from "../components/event-detail"
import PillsNavigation from "../components/pills-navigation"
import LinkBack from "../components/link-back"

const VeranstaltungenPage = ({data, location}) => {

  const events = data.allCalendarEvent.edges;

  const listEvents = (events) => {
    const currentYear = new Date();
    return events.map(({ node }, index) => {
      let hasYearSeparator = false;
      const eventYear = new Date(node.end.dateTime).getFullYear();
      if (eventYear !== currentYear.getFullYear()) {
        hasYearSeparator = true;
        currentYear.setFullYear(eventYear);
      }
      return (
        <>
          { hasYearSeparator && <p className="event-separator text-center text-sm-left" key={eventYear}>{eventYear}</p>}
          <EventDetail event={node} key={index} className="mt-4 mb-2"/>
        </>
      );
    })
  }

  return (
    <Layout>
      <Seo title="Veranstaltungen" />
      <section>
        <Container style={{maxWidth: '720px'}}>
          <p><LinkBack to="/" label={'Homepage'}/></p>
          <Row className="mb-4">
            <Col>
              <PillsNavigation className="news-nav" location={location} items={[
                { name: 'News', href: '/news' },
                { name: 'Veranstaltungen', href: '/veranstaltungen' }
              ]}/>
            </Col>
          </Row>
        </Container>
        <Container>
        { events.length ? listEvents(events) : "Keine aktuellen Veranstaltungen." }
          {/*<Button variant="outline-primary">Alle Veranstaltungen</Button>*/}
        </Container>
      </section>
    </Layout>
  )
}

export const query = graphql`
    query {
      allCalendarEvent(sort: { fields: [end___dateTime], order: ASC }) {
        edges {
          node {
            id
            start {
              dateTime
            }
            summary
            description
            end {
              dateTime
            }
          }
        }
      }
    }
`

export default VeranstaltungenPage
